import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import SecondaryHero from "../components/SecondaryHero";
import SecondaryMenu from "../components/SecondaryMenu";
import StaffList from "../components/StaffList";

const OurTeam = ({ data: { menuItems, settings, teamPage, staff } }) => {
	const menu = menuItems.nodes;
	const allSettings = settings.nodes[0];
	const logoImg = allSettings.logo;
	const contactNum = allSettings.contactNumber;
	const {
		preTitleTitle,
		heroTitle,
		heroSubtitle,
		sectionTitle,
		sectionSubtitle,
	} = teamPage.nodes[0];
	const allStaff = staff.nodes;

	return (
		<Layout>
			<SEO title="Our Network" description={heroSubtitle} />
			<header>
				<SecondaryMenu
					menuItems={menu}
					logo={logoImg}
					contactNumber={contactNum}
				/>
				<SecondaryHero
					preTitle={preTitleTitle}
					heroTitle={heroTitle}
					heroSubtitle={heroSubtitle}
				/>
			</header>
			<main id="main" tabindex="-1">
				<div className="bg-gray-900">
					<div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
						<div className="space-y-12">
							<div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
								<h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
									{sectionTitle}
								</h2>
								<p className="text-xl text-gray-300">{sectionSubtitle}</p>
							</div>
							<StaffList staff={allStaff} />
						</div>
					</div>
				</div>
			</main>
		</Layout>
	);
};

export const query = graphql`
	query ourTeamQuery {
		menuItems: allSanityMenuItems {
			nodes {
				menuItem
				menuLink
				sortOrder
			}
		}
		teamPage: allSanityTeamPage {
			nodes {
				preTitleTitle
				heroTitle
				heroSubtitle
				sectionTitle
				sectionSubtitle
			}
		}
		settings: allSanitySettings {
			nodes {
				contactEmail
				logo {
					...Image
				}
			}
		}
		staff: allSanityStaff {
			nodes {
				location
				name
				sortOrder
				title
				bio
				image {
					...Image
				}
			}
		}
	}
`;

export default OurTeam;
