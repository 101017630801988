import React from "react";
import StaffCard from "./StaffCard";

const StaffList = ({ staff }) => {
	return (
		<ul className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:grid-cols-3 lg:gap-8 ml-0">
			{staff
				.sort((a, b) => (a.sortOrder || -1) - (b.sortOrder || -1))
				.map((props) => (
					<StaffCard {...props} key={props.name} />
				))}
		</ul>
	);
};

export default StaffList;
