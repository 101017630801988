import React from "react";
import Swal from 'sweetalert2';
import confetti from "canvas-confetti";
import { LocationMarkerIcon, UserIcon } from "@heroicons/react/outline";
import Image from "gatsby-plugin-sanity-image";

const ImagePlaceholder = () => (
	<div className="mx-auto bg-gray-400 h-40 w-40 rounded-full xl:w-56 xl:h-56 flex items-center justify-center">
		<div className="text-gray-600 w-1/2">
			<UserIcon />
		</div>
	</div>
);

const StaffCard = ({ bio, image, location, name, title }) => {
	return (
		<li className="py-10 px-6 bg-gray-800 text-center rounded-lg xl:px-10 xl:text-left !mb-4">
			<div>
				{image ? (
					<Image
						{...image}
						width={400}
						className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56 object-cover"
						alt={name}
					/>
				) : (
					<ImagePlaceholder />
				)}
				<div className="font-medium text-lg leading-6">
					<h3 className="text-white mb-0">{name}</h3>
					<p className="text-yellow-500 mb-4">{title}</p>
					<p className="text-sm text-gray-400 leading-relaxed font-normal">
						{bio}
					</p>
					{location && (
						<p className="text-sm text-gray-400 leading-relaxed flex gap-2">
							<LocationMarkerIcon className="w-5 h-5" />
							<span>{location}</span>
						</p>
					)}
				</div>
			</div>
		</li>
	);
};

export default StaffCard;
